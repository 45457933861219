import React from 'react';
import logo from '../images/eurosomlogo.jpg';
import '../css/styles.css';
import '../index.css';
import { Link } from "react-router-dom";
import logoIcon from "../images/chatImage/download.png";


export default function NavigationBar() {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container flex">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>
            <i className="bi bi-list"></i>
          </span>
        </button>
        <Link className="navbar-brand" aria-current="page" to="/">
          <img
            src={logoIcon}
            alt="Eurosom logo"
            style={{
              height: "42px",
              width: "80px",
            }}
          />
        </Link>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarTogglerDemo03"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/services">
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" href="solutions" to="/solutions">
                Solutions
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                // href="https://c.eurosom.com"
                href="data-analytics"
                to="/data-analytics"
              >
                Data
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://odoo.eurosom.com">
                Odoo
              </a>
            </li>
            <li className="nav-item">
              {/* <a className="nav-link" href="https://apps.eurosom.com">Mobile Apps</a> */}
              <a
                className="nav-link"
                href="https://play.google.com/store/apps/details?id=com.eurosom&hl=es_419&gl=US"
              >
                Mobile Apps
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://chatgpt.eurosom.com">
                ChatGPT
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}