import React from "react";
import logo from "../images/eurosomlogo.jpg";
import "../css/styles.css";
import "../index.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer bg-dark py-5">
      <div className="container grid grid-3">
        <div>
          <h1>Eurosom.com</h1>
          <p>Copyright &copy; 2002</p>
        </div>
        <nav>
          <div className="footer-links">
            <Link to="/services">Services</Link>
            <Link to="/solutions">Solutions</Link>
            {/* <a href="https://c.eurosom.com">Cloud</a> */}
            <a href="data-analytics" to="/data-analytics">
              Data
            </a>

            <a href="https://odoo.eurosom.com">Odoo</a>
            {/* <a href="https://apps.eurosom.com">Apps</a> */}
            <a href="https://play.google.com/store/apps/details?id=com.eurosom&hl=es_419&gl=US">
              Mobile Apps
            </a>
            <a href="https://chatgpt.eurosom.com">ChatGPT</a>
          </div>
        </nav>
        <div className="social">
          <a href="https://www.linkedin.com/company/1011865/admin/">
            <i className="fab fa-linkedin fa-2x"></i>
          </a>
          <a href="https://twitter.com/Eurosom1">
            <i className="fa-brands fa-twitter fa-2x"></i>
          </a>
          <a href="https://www.facebook.com/Eurosomcloud/">
            <i className="fab fa-facebook fa-2x"></i>
          </a>
          <a href="https://www.youtube.com/@eurosomso">
            <i className="fab fa-youtube fa-2x"></i>
          </a>
          <a href="https://www.instagram.com/eurosomdata/">
            <i className="fab fa-instagram fa-2x"></i>
          </a>
          <a href="https://www.tiktok.com/@eurosomc">
            <i className="fa-brands fa-tiktok fa-2x"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}
